import type { GetServerSidePropsContext } from 'next';
import { useTranslation } from 'next-i18next';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import {
  TextBlock,
  TextBlockSkeleton,
} from '@hotelplan/components.common.text-block';
import { PageType } from '@hotelplan/graphql.types';
import {
  mapGlobalSearchStateToSearchControlCriteriaInput,
  useGSSContext,
} from '@hotelplan/libs.gss';
import { createLanguageURLsProps } from '@hotelplan/libs.language-urls';
import { apolloReqCtxUnitRef } from '@hotelplan/libs.req-ctx-api';
import { routeConfigUnitRef } from '@hotelplan/libs.route-config-api';
import { ServerSideUnit, ServerSideUnitRef } from '@hotelplan/libs.ssp-units';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { Container } from 'components/core/container';
import { ISearchControlState } from 'components/domain/search-control/components/SearchControlForm/SearchControl.types';
import DynamicComponents from 'components/dynamic-components/DynamicComponents';
import { createPageServerFn } from 'config';
import {
  Get404PageDocument,
  Get404PageQuery,
  Get404PageQueryVariables,
  useGet404PageQuery,
} from 'graphql/404/Get404Page.generated';
import { useGetComponentsQuery } from 'graphql/components/GetComponents.generated';

interface IErrorPageProps {
  statusCode?: number;
  path: string;
}

interface IError404Props {
  pathname: string;
}

const TextBlockWrap = styled(TextBlock)(
  sx2CssThemeFn({
    '.text-block-title': {
      pt: [4, 5],
      mb: 3,
      pl: [3, 0],
    },
    '.text-block-text': {
      backgroundColor: 'white',
      img: {
        display: 'block',
      },
    },
  })
);

function InternalServerError(): ReactElement {
  const [t] = useTranslation('common');
  return (
    <Container>
      <TextBlockWrap
        title={t('common:error.title')}
        text={t('common:error.text')}
      />
    </Container>
  );
}

function Error404({ pathname }: IError404Props): ReactElement {
  const { gss } = useGSSContext<ISearchControlState>();
  const { data, loading } = useGet404PageQuery({});

  const { data: componentsData } = useGetComponentsQuery({
    variables: {
      input: {
        pageType: PageType.Page_404,
        currentUri: pathname,
      },
      gss: mapGlobalSearchStateToSearchControlCriteriaInput(gss),
    },
    // Use here cache-and-network to avoid duplicating dynamic components in
    // existing object in cache.
    fetchPolicy: 'cache-and-network',
  });
  const { title: title404, text: text404 } = data?.pageNotFound404 || {};
  if (loading) return <TextBlockSkeleton uniqueKey="404page" />;

  return (
    <Container>
      <TextBlockWrap title={title404 || ''} text={text404 || ''} />
      <DynamicComponents components={componentsData?.components} />
    </Container>
  );
}

function ErrorPage({ statusCode, path }: IErrorPageProps): ReactElement {
  if (statusCode && statusCode !== 404) {
    return <InternalServerError />;
  }

  return <Error404 pathname={path} />;
}

function getCtxStatusCode(ctx: GetServerSidePropsContext): number {
  return ctx.req.statusCode || ctx.res.statusCode || 500;
}

const errorPageUnit = ServerSideUnit.depsOf([
  apolloReqCtxUnitRef,
  routeConfigUnitRef,
])(async function getErrorData(ctx, apollo, { pathname, searchParams }) {
  const { queryCtx } = apollo;
  const statusCode = getCtxStatusCode(ctx);
  let data: Get404PageQuery | undefined;

  if (statusCode !== 500) {
    const result = await queryCtx<Get404PageQuery, Get404PageQueryVariables>({
      query: Get404PageDocument,
    });
    data = result.data;
  }

  const path = searchParams.toString()
    ? `${pathname}?${searchParams}`
    : pathname;

  return {
    props: {
      path,
      statusCode,
      meta: {
        title: `Error 500`,
        ...(data?.pageNotFound404.meta || null),
      },
    },
  };
}).withRef(new ServerSideUnitRef(`error-page`));

export const getServerSideProps = createPageServerFn(
  {
    pageType: PageType.Page_404,
    async pageEventType(ctx) {
      return getCtxStatusCode(ctx) === 500 ? `error.500` : `error.404`;
    },
    initialProps: createLanguageURLsProps({
      de: { uri: `/`, targetPageType: PageType.Page_404 },
      fr: { uri: `/fr`, targetPageType: PageType.Page_404 },
    }),
  },
  [errorPageUnit]
);

export default ErrorPage;
