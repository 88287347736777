import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { MetaFragmentDoc } from 'graphql/fragments/Meta.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type Get404PageQueryVariables = Types.Exact<{
  context: Types.RequestContextInput;
}>;

export type Get404PageQuery = {
  __typename?: 'Query';
  pageNotFound404: {
    __typename?: 'PageNotFound404Data';
    title: string;
    text: string;
    meta: {
      __typename?: 'PageMetaData';
      title: string;
      description: string;
      index: boolean;
      keywordsTag: string;
      noFollow: boolean;
      openGraphProps: any;
      sitemap: boolean;
    };
  };
};

export type Page404Fragment = {
  __typename?: 'PageNotFound404Data';
  title: string;
  text: string;
  meta: {
    __typename?: 'PageMetaData';
    title: string;
    description: string;
    index: boolean;
    keywordsTag: string;
    noFollow: boolean;
    openGraphProps: any;
    sitemap: boolean;
  };
};

export const Page404FragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'page404' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PageNotFound404Data' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'meta' },
            name: { kind: 'Name', value: 'pageMetaData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'meta' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'title' },
            name: { kind: 'Name', value: 'mainTitle' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'text' },
            name: { kind: 'Name', value: 'mainText' },
          },
        ],
      },
    },
    ...MetaFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const Get404PageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Get404Page' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RequestContextInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageNotFound404' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'page404' },
                },
              ],
            },
          },
        ],
      },
    },
    ...Page404FragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGet404PageQuery__
 *
 * To run a query within a React component, call `useGet404PageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet404PageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet404PageQuery({
 *   variables: {
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGet404PageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Get404PageQuery,
    Get404PageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<Get404PageQuery, Get404PageQueryVariables>(
    Get404PageDocument,
    options
  );
}
export function useGet404PageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Get404PageQuery,
    Get404PageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Get404PageQuery,
    Get404PageQueryVariables
  >(Get404PageDocument, options);
}
export type Get404PageQueryHookResult = ReturnType<typeof useGet404PageQuery>;
export type Get404PageLazyQueryHookResult = ReturnType<
  typeof useGet404PageLazyQuery
>;
export type Get404PageQueryResult = Apollo.QueryResult<
  Get404PageQuery,
  Get404PageQueryVariables
>;
