import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { LinkFragmentFragmentDoc } from 'graphql/link/Link.generated';
export type LinkListFragment = {
  __typename?: 'LinkListComponent';
  linksTitle?: string | null;
  links: Array<{
    __typename?: 'LinkListItem';
    title?: string | null;
    link?:
      | {
          __typename?: 'ExternalLink';
          openMethod: Types.OpenLinkMethod;
          url: string;
          caption?: string | null;
        }
      | {
          __typename?: 'InternalLink';
          caption?: string | null;
          uri: string;
          targetPageType: Types.PageType;
        }
      | null;
  }>;
};

export type LinkListItemFragment = {
  __typename?: 'LinkListItem';
  title?: string | null;
  link?:
    | {
        __typename?: 'ExternalLink';
        openMethod: Types.OpenLinkMethod;
        url: string;
        caption?: string | null;
      }
    | {
        __typename?: 'InternalLink';
        caption?: string | null;
        uri: string;
        targetPageType: Types.PageType;
      }
    | null;
};

export const LinkListItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'linkListItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LinkListItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'link' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'linkFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...LinkFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const LinkListFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'linkList' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LinkListComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'linksTitle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'links' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'linkListItem' },
                },
              ],
            },
          },
        ],
      },
    },
    ...LinkListItemFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
