import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { TextComponentFragmentDoc } from './TextComponent.generated';
export type AccordionComponentFragment = {
  __typename?: 'AccordionComponent';
  bottomBackgroundColor?: string | null;
  bottomFontColor?: string | null;
  bottomMainText?: string | null;
  bottomMainTitle?: string | null;
  topBackgroundColor?: string | null;
  topFontColor?: string | null;
  topMainText?: string | null;
  topMainTitle?: string | null;
  components: Array<
    | { __typename?: 'AccordionComponent' }
    | { __typename?: 'AgencyContactComponent' }
    | { __typename?: 'AgencyRecommendation' }
    | { __typename?: 'BD4TravelRecommendationGroup' }
    | { __typename?: 'BenefitsComponent' }
    | { __typename?: 'BlogArticleRecommendation' }
    | { __typename?: 'BrandsComponent' }
    | { __typename?: 'ClimateComponent' }
    | { __typename?: 'ContactModuleComponent' }
    | { __typename?: 'EmployeesComponent' }
    | {
        __typename?: 'FaqComponent';
        items: Array<{
          __typename?: 'TextComponent';
          title: string;
          text: string;
        }>;
      }
    | { __typename?: 'FlightWithoutPriceRecommendation' }
    | { __typename?: 'FlightWithPriceRecommendation' }
    | { __typename?: 'GeoChildrenComponent' }
    | { __typename?: 'GeoGalleryComponent' }
    | { __typename?: 'GeoRecommendation' }
    | { __typename?: 'HeroMediaGallery' }
    | { __typename?: 'ImageTextComponent' }
    | { __typename?: 'LinkListComponent' }
    | { __typename?: 'MarketingRecommendation' }
    | { __typename?: 'MarketingTeaserComponent' }
    | { __typename?: 'MediaGallery' }
    | { __typename?: 'NewsArticlesComponent' }
    | { __typename?: 'NewsletterSignupComponent' }
    | { __typename?: 'OfferButtonComponent' }
    | { __typename?: 'OffersMapPlaceholderComponent' }
    | { __typename?: 'ProductGalleryWithMapComponent' }
    | { __typename?: 'ProductRecommendation' }
    | { __typename?: 'RequestButtonComponent' }
    | { __typename?: 'ResortTopHotelsComponent' }
    | { __typename?: 'SrlButtonPlaceholderComponent' }
    | { __typename?: 'SrlMapPinsComponent' }
    | { __typename?: 'StaticBlogRecommendation' }
    | { __typename?: 'StaticGeoRecommendation' }
    | { __typename?: 'StaticProductRecommendation' }
    | { __typename?: 'StaticThemeRecommendation' }
    | { __typename?: 'TextComponent' }
    | { __typename?: 'ThemeRecommendation' }
    | { __typename?: 'UspBoxesComponent' }
  >;
};

export const AccordionComponentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'accordionComponent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AccordionComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bottomBackgroundColor' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'bottomFontColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bottomMainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bottomMainTitle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topBackgroundColor' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'topFontColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'topMainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'topMainTitle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'components' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FaqComponent' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'textComponent' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...TextComponentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
