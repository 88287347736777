import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
export type MetaFragment = {
  __typename?: 'PageMetaData';
  title: string;
  description: string;
  index: boolean;
  keywordsTag: string;
  noFollow: boolean;
  openGraphProps: any;
  sitemap: boolean;
};

export const MetaFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'meta' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PageMetaData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'index' } },
          { kind: 'Field', name: { kind: 'Name', value: 'keywordsTag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'noFollow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openGraphProps' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sitemap' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
