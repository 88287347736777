import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { ThemeRecommendationItemFragmentDoc } from 'graphql/theme/ThemeRecommendationItem.generated';
export type ThemeRecommendationFragment = {
  __typename?: 'ThemeRecommendation';
  name?: string | null;
  displayType?: string | null;
  items: Array<{
    __typename?: 'ThemeRecommendationItem';
    id: string;
    name: string;
    destinationName?: string | null;
    duration: number;
    travelType?: Types.TravelType | null;
    totalProductCount?: number | null;
    themeItemText?: string | null;
    link: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
    };
    image: {
      __typename?: 'Image';
      alt: string;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    };
    price: { __typename?: 'Price'; amount: string; currency: string };
    disrupterObject?: {
      __typename?: 'DisrupterObject';
      mainTitle?: string | null;
      subTitle?: string | null;
      text: string;
      fontColor?: string | null;
      backgroundColor?: string | null;
      iconType?: string | null;
    } | null;
  }>;
};

export const ThemeRecommendationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'themeRecommendation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ThemeRecommendation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'themeRecommendationItem' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'displayType' } },
        ],
      },
    },
    ...ThemeRecommendationItemFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
