import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { TextFragmentDoc } from 'graphql/fragments/Text.generated';
import {
  HeroMediaGalleryFragmentDoc,
  MediaGalleryFragmentDoc,
} from './Media.generated';
import { TextComponentFragmentDoc } from 'graphql/fragments/TextComponent.generated';
import { MarketingRecommendationFragmentDoc } from 'graphql/recommendations/MarketingRecommendation.generated';
import { ThemeRecommendationFragmentDoc } from 'graphql/recommendations/ThemeRecommendation.generated';
import { ImageTextComponentFragmentDoc } from 'graphql/fragments/ImageTextComponent.generated';
import { LinkListFragmentDoc } from 'graphql/fragments/LinkList.generated';
import { Bd4recommendationGroupFragmentDoc } from './BD4RecommendationGroup.generated';
import { AccordionComponentFragmentDoc } from 'graphql/fragments/Accordion.generated';
import { GeoRecommendationFragmentDoc } from 'graphql/recommendations/GeoRecommendation.generated';
import { ProductRecommendationFragmentDoc } from 'graphql/recommendations/ProductRecommendation.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetComponentsQueryVariables = Types.Exact<{
  context: Types.RequestContextInput;
  input: Types.ComponentsQueryInput;
  gss?: Types.InputMaybe<Types.GlobalSearchStateInput>;
}>;

export type GetComponentsQuery = {
  __typename?: 'Query';
  components: Array<
    | {
        __typename?: 'AccordionComponent';
        bottomBackgroundColor?: string | null;
        bottomFontColor?: string | null;
        bottomMainText?: string | null;
        bottomMainTitle?: string | null;
        topBackgroundColor?: string | null;
        topFontColor?: string | null;
        topMainText?: string | null;
        topMainTitle?: string | null;
        components: Array<
          | { __typename?: 'AccordionComponent' }
          | { __typename?: 'AgencyContactComponent' }
          | { __typename?: 'AgencyRecommendation' }
          | { __typename?: 'BD4TravelRecommendationGroup' }
          | { __typename?: 'BenefitsComponent' }
          | { __typename?: 'BlogArticleRecommendation' }
          | { __typename?: 'BrandsComponent' }
          | { __typename?: 'ClimateComponent' }
          | { __typename?: 'ContactModuleComponent' }
          | { __typename?: 'EmployeesComponent' }
          | {
              __typename?: 'FaqComponent';
              items: Array<{
                __typename?: 'TextComponent';
                title: string;
                text: string;
              }>;
            }
          | { __typename?: 'FlightWithoutPriceRecommendation' }
          | { __typename?: 'FlightWithPriceRecommendation' }
          | { __typename?: 'GeoChildrenComponent' }
          | { __typename?: 'GeoGalleryComponent' }
          | { __typename?: 'GeoRecommendation' }
          | { __typename?: 'HeroMediaGallery' }
          | { __typename?: 'ImageTextComponent' }
          | { __typename?: 'LinkListComponent' }
          | { __typename?: 'MarketingRecommendation' }
          | { __typename?: 'MarketingTeaserComponent' }
          | { __typename?: 'MediaGallery' }
          | { __typename?: 'NewsArticlesComponent' }
          | { __typename?: 'NewsletterSignupComponent' }
          | { __typename?: 'OfferButtonComponent' }
          | { __typename?: 'OffersMapPlaceholderComponent' }
          | { __typename?: 'ProductGalleryWithMapComponent' }
          | { __typename?: 'ProductRecommendation' }
          | { __typename?: 'RequestButtonComponent' }
          | { __typename?: 'ResortTopHotelsComponent' }
          | { __typename?: 'SrlButtonPlaceholderComponent' }
          | { __typename?: 'SrlMapPinsComponent' }
          | { __typename?: 'StaticBlogRecommendation' }
          | { __typename?: 'StaticGeoRecommendation' }
          | { __typename?: 'StaticProductRecommendation' }
          | { __typename?: 'StaticThemeRecommendation' }
          | { __typename?: 'TextComponent' }
          | { __typename?: 'ThemeRecommendation' }
          | { __typename?: 'UspBoxesComponent' }
        >;
      }
    | { __typename?: 'AgencyContactComponent' }
    | { __typename?: 'AgencyRecommendation' }
    | {
        __typename?: 'BD4TravelRecommendationGroup';
        name?: string | null;
        items: Array<{
          __typename?: 'BD4TravelRecommendation';
          offerId: string;
          name: string;
          travelType?: string | null;
          duration: number;
          mealType: string;
          hpRating?: number | null;
          taRating?: number | null;
          taReviews?: number | null;
          productClass: Types.ProductClass;
          type: Types.ProductType;
          giata: string;
          transferIncluded?: boolean | null;
          featureSummary: Array<{
            __typename?: 'ProductFeatureGroup';
            id: string;
            name: string;
            features: Array<{
              __typename?: 'ProductFeature';
              id: string;
              name: string;
              enabled?: boolean | null;
            }>;
          }>;
          geoBreadcrumbs: Array<{
            __typename?: 'GeoObject';
            id: string;
            name: string;
            type: Types.GeoType;
          }>;
          link: {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
          };
          price: { __typename?: 'Price'; amount: string; currency: string };
          totalPrice: {
            __typename?: 'Price';
            amount: string;
            currency: string;
          };
          transferPrice?: {
            __typename?: 'Price';
            amount: string;
            currency: string;
          } | null;
          image: {
            __typename?: 'Image';
            alt: string;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        }>;
        tracking?: {
          __typename?: 'BD4TravelRecommendationTracking';
          status: Types.Bd4TravelRecommendationStatus;
          productCount?: number | null;
          productIds?: Array<string> | null;
          ptoken?: string | null;
          info?: {
            __typename?: 'BD4TravelRecommendationInfo';
            modelId: string;
            placementId: string;
            strategy: string;
          } | null;
        } | null;
      }
    | { __typename?: 'BenefitsComponent' }
    | { __typename?: 'BlogArticleRecommendation' }
    | { __typename?: 'BrandsComponent' }
    | { __typename?: 'ClimateComponent' }
    | { __typename?: 'ContactModuleComponent' }
    | { __typename?: 'EmployeesComponent' }
    | {
        __typename?: 'FaqComponent';
        items: Array<{
          __typename?: 'TextComponent';
          title: string;
          text: string;
        }>;
      }
    | { __typename?: 'FlightWithoutPriceRecommendation' }
    | { __typename?: 'FlightWithPriceRecommendation' }
    | { __typename?: 'GeoChildrenComponent' }
    | { __typename?: 'GeoGalleryComponent' }
    | {
        __typename?: 'GeoRecommendation';
        name?: string | null;
        titleFontColor?: string | null;
        displayType?: string | null;
        items: Array<{
          __typename?: 'GeoRecommendationItem';
          destinationName?: string | null;
          country?: string | null;
          travelType?: Types.TravelType | null;
          duration: number;
          count: number;
          leadText?: string | null;
          perPersonPrice: {
            __typename?: 'Price';
            amount: string;
            currency: string;
          };
          link: {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
          };
          image: {
            __typename?: 'Image';
            alt: string;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          geoObject: {
            __typename?: 'GeoObject';
            id: string;
            name: string;
            type: Types.GeoType;
          };
        }>;
      }
    | {
        __typename?: 'HeroMediaGallery';
        fontColor?: string | null;
        mainTextFontColor?: string | null;
        mainTitle?: string | null;
        mainText?: string | null;
        mediaItems: Array<
          | {
              __typename?: 'ImageMediaItem';
              title: string;
              image: {
                __typename?: 'Image';
                alt: string;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              };
            }
          | {
              __typename?: 'VideoMediaItem';
              title: string;
              resource: string;
              preview: {
                __typename?: 'Image';
                alt: string;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              };
            }
        >;
      }
    | {
        __typename?: 'ImageTextComponent';
        text: string;
        imagePlacement: Types.ImagePlacement;
        image?: {
          __typename?: 'Image';
          alt: string;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        } | null;
      }
    | {
        __typename?: 'LinkListComponent';
        linksTitle?: string | null;
        links: Array<{
          __typename?: 'LinkListItem';
          title?: string | null;
          link?:
            | {
                __typename?: 'ExternalLink';
                openMethod: Types.OpenLinkMethod;
                url: string;
                caption?: string | null;
              }
            | {
                __typename?: 'InternalLink';
                caption?: string | null;
                uri: string;
                targetPageType: Types.PageType;
              }
            | null;
        }>;
      }
    | {
        __typename?: 'MarketingRecommendation';
        fontColor?: string | null;
        titleFontColor?: string | null;
        backgroundColor?: string | null;
        name?: string | null;
        displayType?: string | null;
        items: Array<{
          __typename?: 'MarketingRecommendationItem';
          title: string;
          subtitle: string;
          mainText: string;
          link:
            | {
                __typename?: 'ExternalLink';
                openMethod: Types.OpenLinkMethod;
                url: string;
                caption?: string | null;
              }
            | {
                __typename?: 'InternalLink';
                caption?: string | null;
                uri: string;
                targetPageType: Types.PageType;
              };
          preview:
            | {
                __typename?: 'ExternalMediaItem';
                resource: string;
                title: string;
                fullScreen: {
                  __typename?: 'Image';
                  alt: string;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
                preview: {
                  __typename?: 'Image';
                  alt: string;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
              }
            | {
                __typename?: 'ImageMediaItem';
                title: string;
                fullScreen: {
                  __typename?: 'Image';
                  alt: string;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
                image: {
                  __typename?: 'Image';
                  alt: string;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
              }
            | {
                __typename?: 'MusicMediaItem';
                resource: string;
                title: string;
                fullScreen: {
                  __typename?: 'Image';
                  alt: string;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
                preview: {
                  __typename?: 'Image';
                  alt: string;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
              }
            | {
                __typename?: 'PdfMediaItem';
                resource: string;
                title: string;
                fullScreen: {
                  __typename?: 'Image';
                  alt: string;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
                preview: {
                  __typename?: 'Image';
                  alt: string;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
              }
            | {
                __typename?: 'VideoMediaItem';
                resource: string;
                title: string;
                fullScreen: {
                  __typename?: 'Image';
                  alt: string;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
                preview: {
                  __typename?: 'Image';
                  alt: string;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
              };
        }>;
      }
    | { __typename?: 'MarketingTeaserComponent' }
    | {
        __typename?: 'MediaGallery';
        mediaItems: Array<
          | {
              __typename?: 'ExternalMediaItem';
              title: string;
              resource: string;
              preview: {
                __typename?: 'Image';
                alt: string;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              };
            }
          | {
              __typename?: 'ImageMediaItem';
              title: string;
              image: {
                __typename?: 'Image';
                alt: string;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              };
              preview: {
                __typename?: 'Image';
                alt: string;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              };
            }
          | {
              __typename?: 'MusicMediaItem';
              title: string;
              resource: string;
              preview: {
                __typename?: 'Image';
                alt: string;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              };
            }
          | {
              __typename?: 'PdfMediaItem';
              title: string;
              resource: string;
              preview: {
                __typename?: 'Image';
                alt: string;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              };
            }
          | {
              __typename?: 'VideoMediaItem';
              title: string;
              resource: string;
              preview: {
                __typename?: 'Image';
                alt: string;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              };
            }
        >;
      }
    | { __typename?: 'NewsArticlesComponent' }
    | { __typename?: 'NewsletterSignupComponent' }
    | { __typename?: 'OfferButtonComponent' }
    | { __typename?: 'OffersMapPlaceholderComponent' }
    | { __typename?: 'ProductGalleryWithMapComponent' }
    | {
        __typename?: 'ProductRecommendation';
        name?: string | null;
        items: Array<{
          __typename?: 'ProductRecommendationItem';
          offerId: string;
          name: string;
          mealType: string;
          duration: number;
          type: Types.ProductType;
          link: {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
          };
          image: {
            __typename?: 'Image';
            alt: string;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          price: { __typename?: 'Price'; amount: string; currency: string };
          geoBreadcrumbs: Array<{
            __typename?: 'GeoObject';
            id: string;
            name: string;
            type: Types.GeoType;
          }>;
          disrupterObject?: {
            __typename?: 'DisrupterObject';
            mainTitle?: string | null;
            subTitle?: string | null;
            text: string;
            fontColor?: string | null;
            backgroundColor?: string | null;
            iconType?: string | null;
          } | null;
        }>;
      }
    | { __typename?: 'RequestButtonComponent' }
    | { __typename?: 'ResortTopHotelsComponent' }
    | { __typename?: 'SrlButtonPlaceholderComponent' }
    | { __typename?: 'SrlMapPinsComponent' }
    | { __typename?: 'StaticBlogRecommendation' }
    | { __typename?: 'StaticGeoRecommendation' }
    | { __typename?: 'StaticProductRecommendation' }
    | { __typename?: 'StaticThemeRecommendation' }
    | {
        __typename?: 'TextComponent';
        title: string;
        text: string;
        type: string;
        textWidth?: string | null;
        backgroundColor?: string | null;
        fontColor?: string | null;
        backgroundImage?: {
          __typename?: 'Image';
          alt: string;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        } | null;
      }
    | {
        __typename?: 'ThemeRecommendation';
        name?: string | null;
        displayType?: string | null;
        items: Array<{
          __typename?: 'ThemeRecommendationItem';
          id: string;
          name: string;
          destinationName?: string | null;
          duration: number;
          travelType?: Types.TravelType | null;
          totalProductCount?: number | null;
          themeItemText?: string | null;
          link: {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
          };
          image: {
            __typename?: 'Image';
            alt: string;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          price: { __typename?: 'Price'; amount: string; currency: string };
          disrupterObject?: {
            __typename?: 'DisrupterObject';
            mainTitle?: string | null;
            subTitle?: string | null;
            text: string;
            fontColor?: string | null;
            backgroundColor?: string | null;
            iconType?: string | null;
          } | null;
        }>;
      }
    | { __typename?: 'UspBoxesComponent' }
  >;
};

export type DynamicComponentAccordionComponentFragment = {
  __typename?: 'AccordionComponent';
  bottomBackgroundColor?: string | null;
  bottomFontColor?: string | null;
  bottomMainText?: string | null;
  bottomMainTitle?: string | null;
  topBackgroundColor?: string | null;
  topFontColor?: string | null;
  topMainText?: string | null;
  topMainTitle?: string | null;
  components: Array<
    | { __typename?: 'AccordionComponent' }
    | { __typename?: 'AgencyContactComponent' }
    | { __typename?: 'AgencyRecommendation' }
    | { __typename?: 'BD4TravelRecommendationGroup' }
    | { __typename?: 'BenefitsComponent' }
    | { __typename?: 'BlogArticleRecommendation' }
    | { __typename?: 'BrandsComponent' }
    | { __typename?: 'ClimateComponent' }
    | { __typename?: 'ContactModuleComponent' }
    | { __typename?: 'EmployeesComponent' }
    | {
        __typename?: 'FaqComponent';
        items: Array<{
          __typename?: 'TextComponent';
          title: string;
          text: string;
        }>;
      }
    | { __typename?: 'FlightWithoutPriceRecommendation' }
    | { __typename?: 'FlightWithPriceRecommendation' }
    | { __typename?: 'GeoChildrenComponent' }
    | { __typename?: 'GeoGalleryComponent' }
    | { __typename?: 'GeoRecommendation' }
    | { __typename?: 'HeroMediaGallery' }
    | { __typename?: 'ImageTextComponent' }
    | { __typename?: 'LinkListComponent' }
    | { __typename?: 'MarketingRecommendation' }
    | { __typename?: 'MarketingTeaserComponent' }
    | { __typename?: 'MediaGallery' }
    | { __typename?: 'NewsArticlesComponent' }
    | { __typename?: 'NewsletterSignupComponent' }
    | { __typename?: 'OfferButtonComponent' }
    | { __typename?: 'OffersMapPlaceholderComponent' }
    | { __typename?: 'ProductGalleryWithMapComponent' }
    | { __typename?: 'ProductRecommendation' }
    | { __typename?: 'RequestButtonComponent' }
    | { __typename?: 'ResortTopHotelsComponent' }
    | { __typename?: 'SrlButtonPlaceholderComponent' }
    | { __typename?: 'SrlMapPinsComponent' }
    | { __typename?: 'StaticBlogRecommendation' }
    | { __typename?: 'StaticGeoRecommendation' }
    | { __typename?: 'StaticProductRecommendation' }
    | { __typename?: 'StaticThemeRecommendation' }
    | { __typename?: 'TextComponent' }
    | { __typename?: 'ThemeRecommendation' }
    | { __typename?: 'UspBoxesComponent' }
  >;
};

export type DynamicComponentAgencyContactComponentFragment = {
  __typename?: 'AgencyContactComponent';
};

export type DynamicComponentAgencyRecommendationFragment = {
  __typename?: 'AgencyRecommendation';
};

export type DynamicComponentBd4TravelRecommendationGroupFragment = {
  __typename?: 'BD4TravelRecommendationGroup';
  name?: string | null;
  items: Array<{
    __typename?: 'BD4TravelRecommendation';
    offerId: string;
    name: string;
    travelType?: string | null;
    duration: number;
    mealType: string;
    hpRating?: number | null;
    taRating?: number | null;
    taReviews?: number | null;
    productClass: Types.ProductClass;
    type: Types.ProductType;
    giata: string;
    transferIncluded?: boolean | null;
    featureSummary: Array<{
      __typename?: 'ProductFeatureGroup';
      id: string;
      name: string;
      features: Array<{
        __typename?: 'ProductFeature';
        id: string;
        name: string;
        enabled?: boolean | null;
      }>;
    }>;
    geoBreadcrumbs: Array<{
      __typename?: 'GeoObject';
      id: string;
      name: string;
      type: Types.GeoType;
    }>;
    link: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
    };
    price: { __typename?: 'Price'; amount: string; currency: string };
    totalPrice: { __typename?: 'Price'; amount: string; currency: string };
    transferPrice?: {
      __typename?: 'Price';
      amount: string;
      currency: string;
    } | null;
    image: {
      __typename?: 'Image';
      alt: string;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    };
  }>;
  tracking?: {
    __typename?: 'BD4TravelRecommendationTracking';
    status: Types.Bd4TravelRecommendationStatus;
    productCount?: number | null;
    productIds?: Array<string> | null;
    ptoken?: string | null;
    info?: {
      __typename?: 'BD4TravelRecommendationInfo';
      modelId: string;
      placementId: string;
      strategy: string;
    } | null;
  } | null;
};

export type DynamicComponentBenefitsComponentFragment = {
  __typename?: 'BenefitsComponent';
};

export type DynamicComponentBlogArticleRecommendationFragment = {
  __typename?: 'BlogArticleRecommendation';
};

export type DynamicComponentBrandsComponentFragment = {
  __typename?: 'BrandsComponent';
};

export type DynamicComponentClimateComponentFragment = {
  __typename?: 'ClimateComponent';
};

export type DynamicComponentContactModuleComponentFragment = {
  __typename?: 'ContactModuleComponent';
};

export type DynamicComponentEmployeesComponentFragment = {
  __typename?: 'EmployeesComponent';
};

export type DynamicComponentFaqComponentFragment = {
  __typename?: 'FaqComponent';
  items: Array<{ __typename?: 'TextComponent'; title: string; text: string }>;
};

export type DynamicComponentFlightWithoutPriceRecommendationFragment = {
  __typename?: 'FlightWithoutPriceRecommendation';
};

export type DynamicComponentFlightWithPriceRecommendationFragment = {
  __typename?: 'FlightWithPriceRecommendation';
};

export type DynamicComponentGeoChildrenComponentFragment = {
  __typename?: 'GeoChildrenComponent';
};

export type DynamicComponentGeoGalleryComponentFragment = {
  __typename?: 'GeoGalleryComponent';
};

export type DynamicComponentGeoRecommendationFragment = {
  __typename?: 'GeoRecommendation';
  name?: string | null;
  titleFontColor?: string | null;
  displayType?: string | null;
  items: Array<{
    __typename?: 'GeoRecommendationItem';
    destinationName?: string | null;
    country?: string | null;
    travelType?: Types.TravelType | null;
    duration: number;
    count: number;
    leadText?: string | null;
    perPersonPrice: { __typename?: 'Price'; amount: string; currency: string };
    link: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
    };
    image: {
      __typename?: 'Image';
      alt: string;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    };
    geoObject: {
      __typename?: 'GeoObject';
      id: string;
      name: string;
      type: Types.GeoType;
    };
  }>;
};

export type DynamicComponentHeroMediaGalleryFragment = {
  __typename?: 'HeroMediaGallery';
  fontColor?: string | null;
  mainTextFontColor?: string | null;
  mainTitle?: string | null;
  mainText?: string | null;
  mediaItems: Array<
    | {
        __typename?: 'ImageMediaItem';
        title: string;
        image: {
          __typename?: 'Image';
          alt: string;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
    | {
        __typename?: 'VideoMediaItem';
        title: string;
        resource: string;
        preview: {
          __typename?: 'Image';
          alt: string;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
  >;
};

export type DynamicComponentImageTextComponentFragment = {
  __typename?: 'ImageTextComponent';
  text: string;
  imagePlacement: Types.ImagePlacement;
  image?: {
    __typename?: 'Image';
    alt: string;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  } | null;
};

export type DynamicComponentLinkListComponentFragment = {
  __typename?: 'LinkListComponent';
  linksTitle?: string | null;
  links: Array<{
    __typename?: 'LinkListItem';
    title?: string | null;
    link?:
      | {
          __typename?: 'ExternalLink';
          openMethod: Types.OpenLinkMethod;
          url: string;
          caption?: string | null;
        }
      | {
          __typename?: 'InternalLink';
          caption?: string | null;
          uri: string;
          targetPageType: Types.PageType;
        }
      | null;
  }>;
};

export type DynamicComponentMarketingRecommendationFragment = {
  __typename?: 'MarketingRecommendation';
  fontColor?: string | null;
  titleFontColor?: string | null;
  backgroundColor?: string | null;
  name?: string | null;
  displayType?: string | null;
  items: Array<{
    __typename?: 'MarketingRecommendationItem';
    title: string;
    subtitle: string;
    mainText: string;
    link:
      | {
          __typename?: 'ExternalLink';
          openMethod: Types.OpenLinkMethod;
          url: string;
          caption?: string | null;
        }
      | {
          __typename?: 'InternalLink';
          caption?: string | null;
          uri: string;
          targetPageType: Types.PageType;
        };
    preview:
      | {
          __typename?: 'ExternalMediaItem';
          resource: string;
          title: string;
          fullScreen: {
            __typename?: 'Image';
            alt: string;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          preview: {
            __typename?: 'Image';
            alt: string;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        }
      | {
          __typename?: 'ImageMediaItem';
          title: string;
          fullScreen: {
            __typename?: 'Image';
            alt: string;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          image: {
            __typename?: 'Image';
            alt: string;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        }
      | {
          __typename?: 'MusicMediaItem';
          resource: string;
          title: string;
          fullScreen: {
            __typename?: 'Image';
            alt: string;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          preview: {
            __typename?: 'Image';
            alt: string;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        }
      | {
          __typename?: 'PdfMediaItem';
          resource: string;
          title: string;
          fullScreen: {
            __typename?: 'Image';
            alt: string;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          preview: {
            __typename?: 'Image';
            alt: string;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        }
      | {
          __typename?: 'VideoMediaItem';
          resource: string;
          title: string;
          fullScreen: {
            __typename?: 'Image';
            alt: string;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          preview: {
            __typename?: 'Image';
            alt: string;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        };
  }>;
};

export type DynamicComponentMarketingTeaserComponentFragment = {
  __typename?: 'MarketingTeaserComponent';
};

export type DynamicComponentMediaGalleryFragment = {
  __typename?: 'MediaGallery';
  mediaItems: Array<
    | {
        __typename?: 'ExternalMediaItem';
        title: string;
        resource: string;
        preview: {
          __typename?: 'Image';
          alt: string;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
    | {
        __typename?: 'ImageMediaItem';
        title: string;
        image: {
          __typename?: 'Image';
          alt: string;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
        preview: {
          __typename?: 'Image';
          alt: string;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
    | {
        __typename?: 'MusicMediaItem';
        title: string;
        resource: string;
        preview: {
          __typename?: 'Image';
          alt: string;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
    | {
        __typename?: 'PdfMediaItem';
        title: string;
        resource: string;
        preview: {
          __typename?: 'Image';
          alt: string;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
    | {
        __typename?: 'VideoMediaItem';
        title: string;
        resource: string;
        preview: {
          __typename?: 'Image';
          alt: string;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
  >;
};

export type DynamicComponentNewsArticlesComponentFragment = {
  __typename?: 'NewsArticlesComponent';
};

export type DynamicComponentNewsletterSignupComponentFragment = {
  __typename?: 'NewsletterSignupComponent';
};

export type DynamicComponentOfferButtonComponentFragment = {
  __typename?: 'OfferButtonComponent';
};

export type DynamicComponentOffersMapPlaceholderComponentFragment = {
  __typename?: 'OffersMapPlaceholderComponent';
};

export type DynamicComponentProductGalleryWithMapComponentFragment = {
  __typename?: 'ProductGalleryWithMapComponent';
};

export type DynamicComponentProductRecommendationFragment = {
  __typename?: 'ProductRecommendation';
  name?: string | null;
  items: Array<{
    __typename?: 'ProductRecommendationItem';
    offerId: string;
    name: string;
    mealType: string;
    duration: number;
    type: Types.ProductType;
    link: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
    };
    image: {
      __typename?: 'Image';
      alt: string;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    };
    price: { __typename?: 'Price'; amount: string; currency: string };
    geoBreadcrumbs: Array<{
      __typename?: 'GeoObject';
      id: string;
      name: string;
      type: Types.GeoType;
    }>;
    disrupterObject?: {
      __typename?: 'DisrupterObject';
      mainTitle?: string | null;
      subTitle?: string | null;
      text: string;
      fontColor?: string | null;
      backgroundColor?: string | null;
      iconType?: string | null;
    } | null;
  }>;
};

export type DynamicComponentRequestButtonComponentFragment = {
  __typename?: 'RequestButtonComponent';
};

export type DynamicComponentResortTopHotelsComponentFragment = {
  __typename?: 'ResortTopHotelsComponent';
};

export type DynamicComponentSrlButtonPlaceholderComponentFragment = {
  __typename?: 'SrlButtonPlaceholderComponent';
};

export type DynamicComponentSrlMapPinsComponentFragment = {
  __typename?: 'SrlMapPinsComponent';
};

export type DynamicComponentStaticBlogRecommendationFragment = {
  __typename?: 'StaticBlogRecommendation';
};

export type DynamicComponentStaticGeoRecommendationFragment = {
  __typename?: 'StaticGeoRecommendation';
};

export type DynamicComponentStaticProductRecommendationFragment = {
  __typename?: 'StaticProductRecommendation';
};

export type DynamicComponentStaticThemeRecommendationFragment = {
  __typename?: 'StaticThemeRecommendation';
};

export type DynamicComponentTextComponentFragment = {
  __typename?: 'TextComponent';
  title: string;
  text: string;
  type: string;
  textWidth?: string | null;
  backgroundColor?: string | null;
  fontColor?: string | null;
  backgroundImage?: {
    __typename?: 'Image';
    alt: string;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  } | null;
};

export type DynamicComponentThemeRecommendationFragment = {
  __typename?: 'ThemeRecommendation';
  name?: string | null;
  displayType?: string | null;
  items: Array<{
    __typename?: 'ThemeRecommendationItem';
    id: string;
    name: string;
    destinationName?: string | null;
    duration: number;
    travelType?: Types.TravelType | null;
    totalProductCount?: number | null;
    themeItemText?: string | null;
    link: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
    };
    image: {
      __typename?: 'Image';
      alt: string;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    };
    price: { __typename?: 'Price'; amount: string; currency: string };
    disrupterObject?: {
      __typename?: 'DisrupterObject';
      mainTitle?: string | null;
      subTitle?: string | null;
      text: string;
      fontColor?: string | null;
      backgroundColor?: string | null;
      iconType?: string | null;
    } | null;
  }>;
};

export type DynamicComponentUspBoxesComponentFragment = {
  __typename?: 'UspBoxesComponent';
};

export type DynamicComponentFragment =
  | DynamicComponentAccordionComponentFragment
  | DynamicComponentAgencyContactComponentFragment
  | DynamicComponentAgencyRecommendationFragment
  | DynamicComponentBd4TravelRecommendationGroupFragment
  | DynamicComponentBenefitsComponentFragment
  | DynamicComponentBlogArticleRecommendationFragment
  | DynamicComponentBrandsComponentFragment
  | DynamicComponentClimateComponentFragment
  | DynamicComponentContactModuleComponentFragment
  | DynamicComponentEmployeesComponentFragment
  | DynamicComponentFaqComponentFragment
  | DynamicComponentFlightWithoutPriceRecommendationFragment
  | DynamicComponentFlightWithPriceRecommendationFragment
  | DynamicComponentGeoChildrenComponentFragment
  | DynamicComponentGeoGalleryComponentFragment
  | DynamicComponentGeoRecommendationFragment
  | DynamicComponentHeroMediaGalleryFragment
  | DynamicComponentImageTextComponentFragment
  | DynamicComponentLinkListComponentFragment
  | DynamicComponentMarketingRecommendationFragment
  | DynamicComponentMarketingTeaserComponentFragment
  | DynamicComponentMediaGalleryFragment
  | DynamicComponentNewsArticlesComponentFragment
  | DynamicComponentNewsletterSignupComponentFragment
  | DynamicComponentOfferButtonComponentFragment
  | DynamicComponentOffersMapPlaceholderComponentFragment
  | DynamicComponentProductGalleryWithMapComponentFragment
  | DynamicComponentProductRecommendationFragment
  | DynamicComponentRequestButtonComponentFragment
  | DynamicComponentResortTopHotelsComponentFragment
  | DynamicComponentSrlButtonPlaceholderComponentFragment
  | DynamicComponentSrlMapPinsComponentFragment
  | DynamicComponentStaticBlogRecommendationFragment
  | DynamicComponentStaticGeoRecommendationFragment
  | DynamicComponentStaticProductRecommendationFragment
  | DynamicComponentStaticThemeRecommendationFragment
  | DynamicComponentTextComponentFragment
  | DynamicComponentThemeRecommendationFragment
  | DynamicComponentUspBoxesComponentFragment;

export const DynamicComponentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'dynamicComponent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DynamicComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TextComponent' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'text' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'HeroMediaGallery' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'heroMediaGallery' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FaqComponent' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'textComponent' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MarketingRecommendation' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'marketingRecommendation' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ThemeRecommendation' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'themeRecommendation' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MediaGallery' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'mediaGallery' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ImageTextComponent' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'imageTextComponent' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LinkListComponent' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'linkList' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BD4TravelRecommendationGroup' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'bd4recommendationGroup' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AccordionComponent' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'accordionComponent' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GeoRecommendation' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'geoRecommendation' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ProductRecommendation' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'productRecommendation' },
                },
              ],
            },
          },
        ],
      },
    },
    ...TextFragmentDoc.definitions,
    ...HeroMediaGalleryFragmentDoc.definitions,
    ...TextComponentFragmentDoc.definitions,
    ...MarketingRecommendationFragmentDoc.definitions,
    ...ThemeRecommendationFragmentDoc.definitions,
    ...MediaGalleryFragmentDoc.definitions,
    ...ImageTextComponentFragmentDoc.definitions,
    ...LinkListFragmentDoc.definitions,
    ...Bd4recommendationGroupFragmentDoc.definitions,
    ...AccordionComponentFragmentDoc.definitions,
    ...GeoRecommendationFragmentDoc.definitions,
    ...ProductRecommendationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const GetComponentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetComponents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RequestContextInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ComponentsQueryInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'gss' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'GlobalSearchStateInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'components' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requestContext' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'gss' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'gss' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'dynamicComponent' },
                },
              ],
            },
          },
        ],
      },
    },
    ...DynamicComponentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetComponentsQuery__
 *
 * To run a query within a React component, call `useGetComponentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComponentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComponentsQuery({
 *   variables: {
 *      context: // value for 'context'
 *      input: // value for 'input'
 *      gss: // value for 'gss'
 *   },
 * });
 */
export function useGetComponentsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetComponentsQuery,
    GetComponentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetComponentsQuery,
    GetComponentsQueryVariables
  >(GetComponentsDocument, options);
}
export function useGetComponentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetComponentsQuery,
    GetComponentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetComponentsQuery,
    GetComponentsQueryVariables
  >(GetComponentsDocument, options);
}
export type GetComponentsQueryHookResult = ReturnType<
  typeof useGetComponentsQuery
>;
export type GetComponentsLazyQueryHookResult = ReturnType<
  typeof useGetComponentsLazyQuery
>;
export type GetComponentsQueryResult = Apollo.QueryResult<
  GetComponentsQuery,
  GetComponentsQueryVariables
>;
