import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { ProductRecommendationItemFragmentDoc } from 'graphql/orl/ProductRecommendationItem.generated';
export type ProductRecommendationFragment = {
  __typename?: 'ProductRecommendation';
  name?: string | null;
  items: Array<{
    __typename?: 'ProductRecommendationItem';
    offerId: string;
    name: string;
    mealType: string;
    duration: number;
    type: Types.ProductType;
    link: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
    };
    image: {
      __typename?: 'Image';
      alt: string;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    };
    price: { __typename?: 'Price'; amount: string; currency: string };
    geoBreadcrumbs: Array<{
      __typename?: 'GeoObject';
      id: string;
      name: string;
      type: Types.GeoType;
    }>;
    disrupterObject?: {
      __typename?: 'DisrupterObject';
      mainTitle?: string | null;
      subTitle?: string | null;
      text: string;
      fontColor?: string | null;
      backgroundColor?: string | null;
      iconType?: string | null;
    } | null;
  }>;
};

export const ProductRecommendationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'productRecommendation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductRecommendation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'productRecommendationItem' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ProductRecommendationItemFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
