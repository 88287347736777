import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { LinkFragmentFragmentDoc } from 'graphql/link/Link.generated';
import { MarketingPreviewFragmentDoc } from './MarketingPreview.generated';
export type MarketingRecommendationItemFragment = {
  __typename?: 'MarketingRecommendationItem';
  title: string;
  subtitle: string;
  mainText: string;
  link:
    | {
        __typename?: 'ExternalLink';
        openMethod: Types.OpenLinkMethod;
        url: string;
        caption?: string | null;
      }
    | {
        __typename?: 'InternalLink';
        caption?: string | null;
        uri: string;
        targetPageType: Types.PageType;
      };
  preview:
    | {
        __typename?: 'ExternalMediaItem';
        resource: string;
        title: string;
        fullScreen: {
          __typename?: 'Image';
          alt: string;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
        preview: {
          __typename?: 'Image';
          alt: string;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
    | {
        __typename?: 'ImageMediaItem';
        title: string;
        fullScreen: {
          __typename?: 'Image';
          alt: string;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
        image: {
          __typename?: 'Image';
          alt: string;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
    | {
        __typename?: 'MusicMediaItem';
        resource: string;
        title: string;
        fullScreen: {
          __typename?: 'Image';
          alt: string;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
        preview: {
          __typename?: 'Image';
          alt: string;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
    | {
        __typename?: 'PdfMediaItem';
        resource: string;
        title: string;
        fullScreen: {
          __typename?: 'Image';
          alt: string;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
        preview: {
          __typename?: 'Image';
          alt: string;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
    | {
        __typename?: 'VideoMediaItem';
        resource: string;
        title: string;
        fullScreen: {
          __typename?: 'Image';
          alt: string;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
        preview: {
          __typename?: 'Image';
          alt: string;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      };
};

export const MarketingRecommendationItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'marketingRecommendationItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MarketingRecommendationItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'link' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'linkFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preview' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingPreview' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mainText' } },
        ],
      },
    },
    ...LinkFragmentFragmentDoc.definitions,
    ...MarketingPreviewFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
