import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { PriceFragmentDoc } from 'graphql/fragments/Price.generated';
import { InternalLinkFragmentFragmentDoc } from 'graphql/link/InternalLink.generated';
import { ResizedImageWithMetaFragmentDoc } from 'graphql/image/ResizedImageWithMeta.generated';
import { GeoFragmentDoc } from 'graphql/geo/Geo.generated';
export type GeoRecommendationFragment = {
  __typename?: 'GeoRecommendation';
  name?: string | null;
  titleFontColor?: string | null;
  displayType?: string | null;
  items: Array<{
    __typename?: 'GeoRecommendationItem';
    destinationName?: string | null;
    country?: string | null;
    travelType?: Types.TravelType | null;
    duration: number;
    count: number;
    leadText?: string | null;
    perPersonPrice: { __typename?: 'Price'; amount: string; currency: string };
    link: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
    };
    image: {
      __typename?: 'Image';
      alt: string;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    };
    geoObject: {
      __typename?: 'GeoObject';
      id: string;
      name: string;
      type: Types.GeoType;
    };
  }>;
};

export type GeoRecommendationItemFragment = {
  __typename?: 'GeoRecommendationItem';
  destinationName?: string | null;
  country?: string | null;
  travelType?: Types.TravelType | null;
  duration: number;
  count: number;
  leadText?: string | null;
  perPersonPrice: { __typename?: 'Price'; amount: string; currency: string };
  link: {
    __typename?: 'InternalLink';
    caption?: string | null;
    uri: string;
    targetPageType: Types.PageType;
  };
  image: {
    __typename?: 'Image';
    alt: string;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
  geoObject: {
    __typename?: 'GeoObject';
    id: string;
    name: string;
    type: Types.GeoType;
  };
};

export const GeoRecommendationItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'geoRecommendationItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GeoRecommendationItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'destinationName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'perPersonPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'price' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'travelType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'link' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'internalLinkFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '384' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '208' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '768' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '392' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '2x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '768' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '416' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '2x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '1536' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '784' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'geoObject' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'geo' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'leadText' } },
        ],
      },
    },
    ...PriceFragmentDoc.definitions,
    ...InternalLinkFragmentFragmentDoc.definitions,
    ...ResizedImageWithMetaFragmentDoc.definitions,
    ...GeoFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const GeoRecommendationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'geoRecommendation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GeoRecommendation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleFontColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'geoRecommendationItem' },
                },
              ],
            },
          },
        ],
      },
    },
    ...GeoRecommendationItemFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
